import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navigation/Navbar.js";
import Experience from './components/timeline';
import About from './components/about';
import Awards from './components/awards';
import './App.css';

function App() {
  return (
    <>
      <Navbar />
      <About />
      <Experience />
      <Awards />
    </>
  );
}

export default App;
import * as React from 'react';
import star from '../star.png';
import list from '../list.png';
import pencil from '../pencil.png';
import medal from '../medal.png';

export default function Awards() {
  return (
    <section id = "award">
    <div>
      <body>
        <p className="header">Awards</p>
        <div className="awards">
          <div className="boxs">
            <div className="images">
              <img class="images" src={list} alt = "list"></img>
            </div>
            <div className="bodys">
              <p>DEAN'S LIST</p>
            </div>
            <div>
              <p className="descriptions">Awarded to qualifiying students who take 12 or more credit hours and achieve a
                GPA of 3.7 or better.</p>
            </div>
            <div>
              <p className="date">Fall 2022, Spring 2023, & Fall 2023</p>
            </div>
          </div>
          <div className="boxs">
            <div className="images">
              <img class="images" src={star} alt = "star"></img>
            </div>
            <div className="bodys">
              <p>EAGLE SCOUT</p>
            </div>
            <div>
              <p className="descriptions">The highest rank achievable in Boy Scouts of America. Canidates must work through
                multiple ranks, become a leader, and complete a service project</p>
            </div>
            <div>
              <p className="date">June 2022</p>
            </div>
          </div>
          <div className="boxs">
            <div className="images">
              <img class="images" src={pencil} alt = "pencil"></img>
            </div>
            <div className="bodys">
              <p>AP SCHOLAR</p>
            </div>
            <div>
              <p className="descriptions">Awarded to students who have taken 3 or more advanced placement exams and
                achieved scores of 3 or better.</p>
            </div>
            <div>
              <p className="date">July 2022</p>
            </div>
          </div>

          <div className="boxs">
            <div className="images">
              <img className="images" src={medal} alt = "medal"></img>
            </div>
            <div className="bodys">
              <p>5TH PLACE</p>
            </div>
            <div>
              <p className="descriptions">Competed against many regional high schools and achieved 5th place in
                the cybersecurity exam administered by FBLA.</p>
            </div>
            <div>
              <p className="date">March 2022</p>
            </div>
          </div>

        </div>
      </body>
    </div >
    </section>
  );
}
import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import WorkIcon from '@mui/icons-material/Work';
import StarIcon from '@mui/icons-material/Star';
import SchoolIcon from '@mui/icons-material/School';
import '../App.css';

function Experience() {
  return (
    <>
      <div className="experiencediv" id = "experience">
        <div className="section-header">
          <h2 id = "title">Experience</h2>
          <p></p>
        </div>
        <VerticalTimeline lineColor={'white'}>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Sept 2023 - Present"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Undergraduate Research/Teaching Assistant</h3>
            <h4 className="vertical-timeline-element-subtitle">Marquette University</h4>
            <p>
            Help out lower level programming students and research.
            </p>
          </VerticalTimelineElement>

        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="May 2023 - Present"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Software Engineer Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">iStream Financial Services</h4>
            <p>
            Design, program, debug, and test software.
                        </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Sept 2022 - Present"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Student Web Developer</h3>
            <h4 className="vertical-timeline-element-subtitle">Marquette University</h4>
            <p>
              Design, develop, and implement webpages for the library.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Jan 2022 - Present"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Environmental Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Conservancy for Healing and Heritage</h4>
            <p>
              Monitor and survey the various wildlife species. As well as general maintenance of facilities.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="May 2022 - June 2023"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Produce Associate</h3>
            <h4 className="vertical-timeline-element-subtitle">Pick ‘n Save</h4>
            <p>
              Restock, organize, and serve the customers in the produce department
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Aug 2022 - May 2026"
            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
            icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">Bachelor Science of Computer Science</h3>
            <p>
              Marquette University
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Sept 2018 - Jun 2022"
            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
            icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">High School Diploma</h3>
            <p>
              Franklin High School
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
            icon={<StarIcon />}
          />
        </VerticalTimeline>
      </div>
    </>
  );
}
export default Experience;

import React from "react";
import github from '../GitHub-Mark-32px.png';
import picture from '../editedprofileimage.png';
import mail from '../mail.png';
import Divider from '@mui/material/Divider';
import linkEdin from '../linkedinLogo.png'
import '../App.css';

function About() {
    return (
        <>
            <section id = "about">
            <div className="about-me">
                <div className="profileimage">
                    <img class="image" src={picture} alt = "Benjamin Gebhard" />
                </div>
                <div className="profile">
                    <div className="sizeProfile">
                        <br />
                        <br />
                        <h1 className="name">Benjamin Gebhard</h1>
                        <h2 className="role">Student Web Developer @ Marquette University</h2>
                        <div className="icons">
                            <a href="https://github.com/Axre2"><img class="github-image" src={github} alt = "github logo" /></a>
                            <a href="mailto:benjamin.gebhard@marquette.edu"><img class="mail-image" src={mail} alt = "mailbox" /></a>
                            <a href="https://www.linkedin.com/in/benjamin-gebhard-3a8235254"><img class="linkedin-image" src={linkEdin} alt = "linkEdin logo" /></a>
                        </div>
                        < Divider classname="divider" />
                        <div className="description">
                            <p>
                            Hello, I'm Benjamin Gebhard, a sophomore at Marquette University studying Computer Science with a minor in Mathematics. Over the past few months, I've had the privilege of working as a Software Engineer Intern at iStream Financial Services. During my internship, I helped in migrating our hosted payment page to a more modern version of Angular, which eliminated some security concerns. Additionally, I designed and created comprehensive API documentation, enabling our users to seamlessly utilize our APIs. I'm excited to continue learning about software engineering and eager to make an impact through meaningful projects. Feel free to reach out anytime.                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </>
    );
}

export default About;